import type { DataDrivenPropertyValueSpecification } from '@maplibre/maplibre-gl-style-spec';
import type { FeatureCollection, LineString, Point } from '@turf/turf';
import type { Course, CourseControl, Event, ResolvedControl } from 'core';
import {
  controlLinesToGeometry,
  controlsToGeometry,
  getEventScale,
  isRogaine,
  resolveControls,
} from 'core';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Layer, Source, useMap } from 'react-map-gl';
import { useLocalStorage } from '../../../hooks/use-local-storage.js';
import { ToggleLinesControl } from '../controls/toggle-lines.js';
import { ToggleNumbersControl } from '../controls/toggle-numbers.js';
import { MapImage } from '../map-image.js';
import isom701Casing from '../markers/isom-701-casing.svg';
import isom701 from '../markers/isom-701.svg';
import isom703Casing from '../markers/isom-703-casing.svg';
import isom703 from '../markers/isom-703.svg';
import isom706Casing from '../markers/isom-706-casing.svg';
import isom706 from '../markers/isom-706.svg';

interface CourseLayerProps {
  controls?: ResolvedControl[];
  course: Pick<Course.Type, '_id' | 'controls'>;
  event: Pick<Event.Type, 'controls' | 'type'>;
  highlightControls?: CourseControl[];
}

interface ControlsLayerProps {
  controls: ResolvedControl[];
  eventType?: string | null;
  highlightControls?: CourseControl[];
  id: string;
}

interface ViewProps {
  controlGeometry: FeatureCollection<Point>;
  lineGeometry?: FeatureCollection<LineString>;
  id: string;
  scale: number;
  showNumbers: boolean;
  toggleLines?: () => void;
  toggleNumbers?: () => void;
}

const convertNominal = (nominal: number, zoom: number): number =>
  nominal * 2 ** (zoom - 16.5);

const widthInterpolation = (
  nominal: number,
  scale: number,
): DataDrivenPropertyValueSpecification<number> => [
  'interpolate',
  ['exponential', 2],
  ['zoom'],
  // min at zoom 15
  15 + Math.log(1 / scale) / Math.log(2),
  convertNominal(nominal, 15 + Math.log(1 / scale) / Math.log(2)) * scale,
  // max at zoom 18
  18 + Math.log(1 / scale) / Math.log(2),
  convertNominal(nominal, 18 + Math.log(1 / scale) / Math.log(2)) * scale,
];

const iconSizeInterpolation = (
  nominal: number,
  scale: number,
): DataDrivenPropertyValueSpecification<number> => [
  'interpolate',
  ['exponential', 2],
  ['zoom'],
  // min at zoom 15
  15 + Math.log(1 / scale) / Math.log(2),
  (convertNominal(nominal, 15 + Math.log(1 / scale) / Math.log(2)) / 180) *
    scale,
  // max at zoom 18
  18 + Math.log(1 / scale) / Math.log(2),
  (convertNominal(nominal, 18 + Math.log(1 / scale) / Math.log(2)) / 180) *
    scale,
];

const opacityInterpolation = (
  scale: number,
): DataDrivenPropertyValueSpecification<number> => [
  'interpolate',
  ['exponential', 2],
  ['zoom'],
  // min at zoom 15
  15 + Math.log(1 / scale) / Math.log(2),
  ['/', ['get', 'opacity'], 2],
  // max at zoom 16
  16 + Math.log(1 / scale) / Math.log(2),
  0,
];

type MapMouseEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData;

export const CourseLayer: FC<CourseLayerProps> = ({
  controls,
  course,
  event,
  highlightControls,
}) => {
  const [showLines, setShowLines] = useLocalStorage('show-course-lines', true);
  const [showNumbers, setShowNumbers] = useLocalStorage(
    'show-control-numbers',
    true,
  );
  const scale = getEventScale(event.type);
  const resolvedControls =
    controls ?? resolveControls(course.controls, event.controls, event.type);
  const controlGeometry = useMemo(
    () =>
      controlsToGeometry(
        resolvedControls,
        scale,
        highlightControls,
        !isRogaine(event.type),
      ),
    [highlightControls, event.type, resolvedControls, scale],
  );
  const { default: map } = useMap();

  useEffect(() => {
    if (!map) return;

    let ids: (string | number | undefined)[] = [];

    const move = (event: MapMouseEvent): void => {
      ids.forEach((id) =>
        map.removeFeatureState({
          source: 'control-data-source',
          id,
        }),
      );
      if (!event.features?.length) {
        ids = [];
        return;
      }

      ids = event.features.map((f) => f.id).filter((id) => !!id);

      ids.forEach((id) =>
        map.setFeatureState(
          {
            source: 'control-data-source',
            id,
          },
          {
            hover: true,
          },
        ),
      );
    };
    const leave = (): void => {
      ids.forEach((id) =>
        map.removeFeatureState({
          source: 'control-data-source',
          id,
        }),
      );
    };

    map.on('mousemove', 'control-layer-source', move);
    map.on('mouseleave', 'control-layer-source', leave);

    return () => {
      map.off('mousemove', 'control-layer-source', move);
      map.off('mouseleave', 'control-layer-source', leave);
    };
  }, [map]);

  const lineGeometry = useMemo(
    () =>
      showLines && !isRogaine(event.type)
        ? controlLinesToGeometry(resolvedControls, scale, highlightControls)
        : undefined,
    [resolvedControls, showLines, highlightControls, event.type, scale],
  );
  const toggleLines = !isRogaine(event.type)
    ? (): void => setShowLines(!showLines)
    : undefined;
  const toggleNumbers = (): void => setShowNumbers(!showNumbers);

  return CourseLayerView({
    id: 'source',
    controlGeometry,
    lineGeometry,
    scale,
    showNumbers,
    toggleLines,
    toggleNumbers,
  });
};

export const ControlsLayer: FC<ControlsLayerProps> = ({
  controls,
  eventType,
  highlightControls,
  id,
}) => {
  const scale = getEventScale(eventType);
  const controlGeometry = useMemo(
    () => controlsToGeometry(controls, scale, highlightControls, false),
    [controls, highlightControls, scale],
  );

  return CourseLayerView({ id, controlGeometry, scale, showNumbers: true });
};

export const CourseLayerView: FC<ViewProps> = ({
  controlGeometry,
  lineGeometry,
  id,
  scale,
  showNumbers,
  toggleLines,
  toggleNumbers,
}) => (
  <>
    {toggleLines ? <ToggleLinesControl onToggle={toggleLines} /> : null}
    {toggleNumbers ? <ToggleNumbersControl onToggle={toggleNumbers} /> : null}
    <MapImage id="isom-701" size={500} svg={isom701} />
    <MapImage id="isom-701-casing" size={500} svg={isom701Casing} />
    <MapImage id="isom-703" size={500} svg={isom703} />
    <MapImage id="isom-703-casing" size={500} svg={isom703Casing} />
    <MapImage id="isom-706" size={500} svg={isom706} />
    <MapImage id="isom-706-casing" size={500} svg={isom706Casing} />

    <Source id={`control-data-${id}`} type="geojson" data={controlGeometry}>
      <Layer
        id={`control-layer-${id}`}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-image': ['get', 'icon'],
          'icon-anchor': 'center',
          'icon-size': iconSizeInterpolation(25 - 1.75, scale),
          'icon-rotate': ['get', 'rotation'],
          'icon-rotation-alignment': 'map',
          'text-field': ['get', 'id'],
          'text-optional': true,
          'text-ignore-placement': true,
          'text-radial-offset': 0.7,
          'text-variable-anchor': [
            'bottom-right',
            'bottom-left',
            'top-left',
            'bottom',
            'top',
            'right',
            'left',
          ],
          'text-justify': 'auto',
          'text-size': iconSizeInterpolation(8000, scale),
        }}
        paint={{
          'icon-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            ['get', 'opacity'],
          ],
          'text-color': '#a626ff',
          'text-halo-color': [
            'step',
            ['zoom'],
            'rgba(255, 255, 255, 0.5)',
            16 + Math.log(1 / scale) / Math.log(2),
            'transparent',
          ],
          'text-halo-width': 2,
          'text-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            showNumbers ? ['get', 'opacity'] : 0,
          ],
        }}
      />
      <Layer
        id={`control-casing-layer-${id}`}
        beforeId={`control-layer-${id}`}
        type="symbol"
        layout={{
          'icon-allow-overlap': true,
          'icon-image': ['get', 'icon-casing'],
          'icon-anchor': 'center',
          'icon-size': iconSizeInterpolation(25 - 1.75, scale),
          'icon-rotate': ['get', 'rotation'],
          'icon-rotation-alignment': 'map',
        }}
        paint={{ 'icon-opacity': opacityInterpolation(scale) }}
      />
    </Source>
    {lineGeometry ? (
      <Source
        id={`course-data-${id}`}
        type="geojson"
        data={lineGeometry}
        lineMetrics={true}
      >
        <Layer
          id={`course-layer-${id}`}
          beforeId={`control-casing-layer-${id}`}
          type="line"
          paint={{
            'line-opacity': ['get', 'opacity'],
            'line-color': '#a626ff',
            'line-width': widthInterpolation(3.5, scale),
          }}
        />
        <Layer
          id={`course-casing-layer-${id}`}
          beforeId={`course-layer-${id}`}
          type="line"
          paint={{
            'line-opacity': opacityInterpolation(scale),
            'line-color': 'white',
            'line-width': widthInterpolation(10.5, scale),
          }}
        />
      </Source>
    ) : undefined}
  </>
);
