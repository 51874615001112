import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import { MapboxGLButtonGroup } from './button-group.js';

interface Props {
  onToggle: () => void;
}

export const ToggleNumbersControl: FC<Props> = ({ onToggle }) => {
  const { current } = useMap();
  const control = useMemo(() => new MapboxGLButtonGroup(), []);

  useEffect(() => {
    current?.addControl(control);

    return () => {
      current?.removeControl(control);
    };
  }, [current, control]);

  useEffect(() => {
    control.setButtons([
      {
        content: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="28" height="28" fill="white"/>
        <path d="M19.9789 15C19.4064 15 18.8965 14.8902 18.4492 14.6706C18.0045 14.4482 17.653 14.1431 17.3949 13.7552C17.1393 13.3645 17.0077 12.914 17 12.4035H18.6716C18.6818 12.6174 18.7444 12.8056 18.8594 12.9682C18.977 13.1279 19.1329 13.2519 19.3271 13.3403C19.5214 13.4287 19.7399 13.4729 19.9827 13.4729C20.2358 13.4729 20.4594 13.423 20.6537 13.3232C20.8479 13.2234 21 13.0851 21.1099 12.9083C21.2198 12.7315 21.2747 12.5276 21.2747 12.2966C21.2747 12.0627 21.216 11.856 21.0984 11.6763C20.9834 11.4938 20.8172 11.3512 20.6 11.2486C20.3853 11.1459 20.1297 11.0946 19.8332 11.0946H19.101V9.73432H19.8332C20.0837 9.73432 20.3048 9.68584 20.4965 9.58888C20.6907 9.49192 20.8415 9.35789 20.9489 9.18679C21.0562 9.01283 21.1099 8.81036 21.1099 8.57937C21.1099 8.35979 21.0626 8.1673 20.968 8.0019C20.876 7.83365 20.7457 7.70247 20.577 7.60837C20.4108 7.51426 20.2166 7.46721 19.9942 7.46721C19.7693 7.46721 19.5636 7.51283 19.377 7.60409C19.1904 7.69249 19.0409 7.81939 18.9284 7.98479C18.816 8.15019 18.7559 8.34411 18.7482 8.56654H17.1572C17.1649 8.06179 17.2939 7.61692 17.5444 7.23194C17.7949 6.84696 18.1323 6.5461 18.5565 6.32937C18.9834 6.10979 19.4652 6 20.0019 6C20.5438 6 21.0179 6.10979 21.4243 6.32937C21.8306 6.54895 22.1463 6.84553 22.3712 7.21911C22.5987 7.58983 22.7112 8.00618 22.7086 8.46816C22.7112 8.95865 22.5744 9.36787 22.2984 9.69582C22.0249 10.0238 21.6683 10.2319 21.2287 10.3203V10.3888C21.8064 10.4715 22.246 10.6953 22.5476 11.0604C22.8517 11.4225 23.0025 11.876 23 12.4206C23.0025 12.9197 22.8735 13.3631 22.6128 13.751C22.3546 14.1388 21.9981 14.4439 21.5431 14.6664C21.0882 14.8888 20.5668 15 19.9789 15Z" fill="black"/>
        <path d="M8.67898 6.27273V15H6.57387V8.24148H6.52273L4.57103 9.43466V7.6108L6.72302 6.27273H8.67898Z" fill="black"/>
        <path d="M10.6435 24V22.483L13.8267 19.696C14.0653 19.4801 14.2685 19.2827 14.4361 19.1037C14.6037 18.9219 14.7315 18.7401 14.8196 18.5582C14.9077 18.3736 14.9517 18.1733 14.9517 17.9574C14.9517 17.7159 14.8991 17.5099 14.794 17.3395C14.6889 17.1662 14.544 17.0327 14.3594 16.9389C14.1747 16.8452 13.9631 16.7983 13.7244 16.7983C13.483 16.7983 13.2713 16.848 13.0895 16.9474C12.9077 17.044 12.7656 17.1847 12.6634 17.3693C12.5639 17.554 12.5142 17.7784 12.5142 18.0426H10.5156C10.5156 17.4489 10.6491 16.9361 10.9162 16.5043C11.1832 16.0724 11.5582 15.7401 12.0412 15.5071C12.527 15.2713 13.0909 15.1534 13.733 15.1534C14.3949 15.1534 14.9702 15.2642 15.4588 15.4858C15.9474 15.7074 16.3253 16.017 16.5923 16.4148C16.8622 16.8097 16.9972 17.2685 16.9972 17.7912C16.9972 18.1236 16.9304 18.4531 16.7969 18.7798C16.6634 19.1065 16.4233 19.4673 16.0767 19.8622C15.733 20.2571 15.2443 20.7301 14.6108 21.2812L13.5668 22.2443V22.2997H17.1037V24H10.6435Z" fill="black"/>
        </svg>`,
        key: 'toggle-numbers',
        title: 'Toggle control numbers',
        eventHandler: () => onToggle(),
      },
    ]);
  }, [onToggle, control]);
  return null;
};
