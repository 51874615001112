import { addBreadcrumb, captureException } from '@sentry/browser';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-map-gl';

interface Props {
  id: string;
  size: number;
  svg: string;
}

export const MapImage: FC<Props> = ({ id, size, svg }) => {
  const { current: map } = useMap();

  useEffect(() => {
    if (!map || map.hasImage(id)) return;

    const mapImage = new Image(size, size);
    mapImage.onload = () => {
      try {
        if (!map || map.hasImage(id)) return;
      } catch (err) {
        addBreadcrumb({
          level: 'warning',
          message: String(err),
        });
        return;
      }
      try {
        map.addImage(id, mapImage);
      } catch (err) {
        captureException(err);
      }
    };
    mapImage.src = svg;
  }, [map, id, svg, size]);

  return null;
};
