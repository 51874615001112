import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import { MapboxGLButtonGroup } from './button-group.js';

interface Props {
  onToggle: () => void;
}

export const ToggleLinesControl: FC<Props> = ({ onToggle }) => {
  const { current } = useMap();
  const control = useMemo(() => new MapboxGLButtonGroup(), []);

  useEffect(() => {
    current?.addControl(control);

    return () => {
      current?.removeControl(control);
    };
  }, [current, control]);

  useEffect(() => {
    control.setButtons([
      {
        content: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="28" height="28" fill="white"/>
        <path d="M11 17L17 11" stroke="black" stroke-width="2"/>
        <circle cx="8" cy="20" r="3" stroke="black" stroke-width="2"/>
        <circle cx="20" cy="8" r="3" stroke="black" stroke-width="2"/>
      </svg>`,
        key: 'toggle-lines',
        title: 'Toggle course lines',
        eventHandler: () => onToggle(),
      },
    ]);
  }, [onToggle, control]);
  return null;
};
