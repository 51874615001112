const numericCode = /^\d{2,}$/;

export const defaultPoints = (
  code: string,
  controlType: string,
): number | undefined => {
  if (!numericCode.test(code)) return undefined;
  if (['start', 'finish'].includes(controlType)) return 0;

  const char = code.slice(-2, -1);

  return Number(char) * 10;
};

export const defaultPointsString = (
  code: string,
  controlType: string,
): string | undefined => {
  const points = defaultPoints(code, controlType);
  return points !== undefined ? String(points) : undefined;
};

export const defaultControlRadius = (
  eventType: string | null | undefined,
  override?: number | null,
): number => override ?? (eventType === 'sprint' ? 15 : 30);

export const defaultControlRadiusString = (
  eventType: string | null | undefined,
  override?: number | null,
): string => {
  const radius = defaultControlRadius(eventType, override);
  return String(radius);
};
